import React, { createRef, useEffect, useRef, useState } from "react";
import { ReactDOM } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback } from "react";
import { CSVLink, CSVDownload } from "react-csv";
import ReactDOMServer from 'react-dom/server';
import { status } from "./Functions";
import moment from "moment";

export default function Table({ 
    id,
    gridTemplateColumns, 
    gridTemplateRows,
    grid, 
    headers, 
    columns, 
    rows, 
    defaultSort, 
    rowPadding, 
    perPage, 
    showResult, 
    unit, 
    onRowClick,
    onCtrlClick,
    rowClass,
    ifEmpty, 
    customSort,
    loading,
    filters,
    exportRef
}){


    const tableRef = useRef()
    
    const [rowProp, setRowProp] = useState(rows)
    const [actualRows, setActualRows] = useState(rows)
    const [currentRows, setCurrentRows] = useState(rows) 
    const [sort, setSort] = useState()
    const [userAction, setUserAction] = useState(false)
    const [pagination, setPagination] = useState(1)
    const [paginationSteps, setPaginationSteps] = useState(null)
    const [pageLimit, setPageLimit] = useState(perPage)
    const [exportData, setExportData] = useState(null)

    const [windowKey, setWindowKey] = useState(0)
    const [style, setStyle] = useState({
        header: {
            gridTemplateColumns: gridTemplateColumns ? gridTemplateColumns[windowKey] : grid,
            gridTemplateRows: gridTemplateRows ? gridTemplateRows[windowKey] : null
        },
        row: {
            gridTemplateColumns: gridTemplateColumns ? gridTemplateColumns[windowKey] : grid,
            gridTemplateRows: gridTemplateRows ? gridTemplateRows[windowKey] : null,
        }
    })

    useEffect(() => {
        if(exportRef && exportRef.current){
            exportRef?.current?.addEventListener('click', () => exportToCsv)
            return () => exportRef?.current?.addEventListener('click', exportToCsv)
        }
    }, [exportRef, actualRows])

    const exportToCsv = () => {
        setExportData(null)
        if (actualRows) {
            const data = [];
            /** HEADER */
            const headerOutput = headers.map((h) => h.title);
            data.push(headerOutput);
            
            /** BODY */
            actualRows?.forEach((row, key) => {
                data.push(
                    columns.map((column, key) => {
                        return JSXToText(column.display(row));
                    })
                );
            });
            /** FOOTER (TOTAL) */
            const footer = []
                if(headers && headers.filter((h) => h.total).length){
                headers.map((header, key) =>  footer.push(key === 0 ? 'Total' : (header.total ?  ( header.totalCalculation ? header.totalCalculation(header.key) : getTotal(header))  : '')))
                data.push(footer)
            }

            //Setting data for download
            setExportData(data)
        }else{
            status(9, 'Table not done drawing')
        }
    };

    const JSXToText = (element) => {
        if (typeof element === 'string') {
        // Hvis elementet allerede er en streng, returner den uendret
        return element;
        }
    
        if (typeof element === 'function') {
        // Hvis elementet er en funksjon, kall den for å få JSX-elementet
        element = element();
        }
    
        if (React.isValidElement(element)) {
            const htmlString = ReactDOMServer.renderToStaticMarkup(element);
            const div = document.createElement('div');
            div.innerHTML = htmlString;
            return div.innerText;
        }
    
        // Hvis elementet ikke er en streng eller React-komponent, returner en tom streng
        return '';
    };

    useEffect(() => {
        if(id){
            loadSettings()
        }
    }, [])

    const saveSettings = () => {
        if(id){
            let store = JSON.parse(localStorage.getItem('sr.tableSettings'))
            if(!store){
                store = {}
            }

            store[id] = {
                pageLimit: pageLimit,
                pagination: pagination
            }

            localStorage.setItem('sr.tableSettings', JSON.stringify(store))
        }
    }

    const loadSettings = () => {
        let store = JSON.parse(localStorage.getItem('sr.tableSettings'))

        if(store && store[id]){
            setPageLimit(store[id].pageLimit)
            setPagination(paginationSteps >= store[id].pagination ? store[id].pagination : 1)
        }
    }

    useEffect(() => {
        saveSettings()
    }, [pageLimit, pagination])

    useEffect(() => {
        if(rowProp){
            setActualRows([...rowProp])
        }
    }, [rowProp])

    useEffect(() => {
        if(rows){
            setRowProp([...rows])
        }
    }, [rows])

    useEffect(() => {
        if(actualRows){
            setCurrentRows([...actualRows])
        }
    }, [actualRows])

    useEffect(() => {
        if(pageLimit && actualRows && currentRows){
            setPaginationSteps(Math.ceil(actualRows.length / pageLimit))
        }
    }, [actualRows, currentRows, pageLimit])

    useEffect(() => {
       
        if(actualRows && Array.isArray(actualRows) && pageLimit){
            setCurrentRows(
                [...actualRows].slice((pagination * pageLimit) - pageLimit, pageLimit * pagination)
            )
        }
    }, [actualRows, pageLimit])

    useEffect(() => {
        if(actualRows && Array.isArray(actualRows)){
            setCurrentRows(
                [...actualRows].slice((pagination * pageLimit) - pageLimit, pageLimit * pagination)
            )
        }
    }, [paginationSteps, pagination, pageLimit])

    useEffect(() => {
        
        if(sort && rowProp){
            const { key, direction } = sort
            const newActualRows = [...rowProp]
            const header = headers.filter(h => h.key === key)[0]
            
            if(!header) return
            const sortType = header.sort

            
            if(header.sortValue){
                newActualRows.sort((a, b) => {
                    const asort = header.sortValue(a)
                    const bsort = header.sortValue(b)
                    return sortOnType({
                        a: direction === 'ASC' ? asort : bsort,
                        b: direction === 'ASC' ? bsort : asort,
                        type: header.sort
                    })
                })
            }else{
                newActualRows.sort((a, b) => {
                    return sortOnType({
                        a: direction === 'ASC' ? a[key] : b[key],
                        b: direction === 'ASC' ? b[key] : a[key],
                        type: sortType
                    })
                })
            }
            
            
            setActualRows(
                newActualRows
            )
        }
    }, [sort, rowProp])

    const getTotal = ({ key, sort }) => {
        if(rows?.length){
            switch(sort){
                case 'number':
                    return rows.reduce((accumulator, row) => {
                        return accumulator + parseInt(row[key])
                    }, 0).toLocaleString('nb-NO')
                case 'array':
                    return rows.reduce((accumulator, row) => {
                        return accumulator + row[key].length
                    }, 0)
                default:
                    return ''
                    
            }
        }else{
            return '-'
        }
    }

    

    useEffect(() => {
        if(headers && actualRows && !sort) setSort(defaultSort ? defaultSort : {key: headers[0].key, direction: 'ASC'})
    }, [rows, actualRows])

    //Functions
    const  sortOnType = (props) => {
        
        const { a, b, type } = props
        if(!a) return -1
        if(!b) return 1
        
        switch(type){
            case 'number':
                return parseInt(a) - parseInt(b)
            case 'string':
                return a.localeCompare(b)
            case 'array':
                return a.length - b.length
            case 'date':
                return moment(a).format('X') - moment(b).format('X')
            default:
                return a - b
        }
    }


    const drawPagination = () => {
       
        const items = []

        const goBack = () => {
            if((pagination - 1) > 0) setPagination(pagination - 1)   
        }

        const goForward = () => {
            if(pagination < paginationSteps) setPagination(pagination + 1)   
        }

        const goToClicked = (event) => {
            setPagination(parseInt(event.target.dataset.index))
        }

        items.push(<span key={1} onClick={() => setPagination(1)} className={1 === pagination ? 'active' : ''}>{1}</span>)

        let i = pagination - 2
        do{
            if(i >= 2 && i <= (paginationSteps + 1) ){
                items.push(<span data-index={i} key={i} onClick={(e) => goToClicked(e)} className={i === pagination ? 'active' : ''}>{i}</span>)          
            }
            i++
            if(i >= paginationSteps) break
        }
        while(items.length <= 5)

        

        items.push(<span key={paginationSteps} onClick={() => setPagination(paginationSteps)} className={paginationSteps === pagination ? 'active' : ''}>{paginationSteps}</span>)

        
        return (
            <div className="pagination">
                <span className="disabled" disabled onClick={() => goBack()}><FontAwesomeIcon icon={['fal','chevron-left']} /></span>
                {items}
                <span onClick={() => goForward()}><FontAwesomeIcon icon={['fal','chevron-right']} /></span>
            </div>
        )
    }

    const drawCurrentVisibleRows = () => {


        const visibleItemsStart = ((pageLimit * pagination) + 1) - pageLimit;
        const visibleItemsEnd = visibleItemsStart + currentRows.length - 1;
        if(pageLimit < actualRows.length){
            return( 
                <span>{visibleItemsStart} - {visibleItemsEnd}</span>
            )
        }else{
            return( 
                <span>{visibleItemsStart} - {visibleItemsEnd}</span> 
            )
        }
    }

    function handleResize() {

        if(tableRef.current){
            switch(true){
                case tableRef.current.clientWidth > 1024:
                    setWindowKey(0)
                    break;
                case tableRef.current.clientWidth < 1024 && tableRef.current.clientWidth > 767:
                    setWindowKey(1)
                    break;
                case tableRef.current.clientWidth < 767:
                    setWindowKey(2)
                    break;
                default:

            }   
        }
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize)
    
        return _ => {
          window.removeEventListener('resize', handleResize)
        }
    }, [])

    const updatePageLimit = (newPageLimit) => {
        if(newPageLimit > 0) setPageLimit(newPageLimit)
    }

    useEffect(() => {
        handleResize()
    }, [])

    useEffect(() => {
        setStyle({
            header: {
                gridTemplateColumns: gridTemplateColumns ? gridTemplateColumns[windowKey] : grid,
                gridTemplateRows: gridTemplateRows ? gridTemplateRows[windowKey] : null
            },
            row: {
                gridTemplateColumns: gridTemplateColumns ? gridTemplateColumns[windowKey] : grid,
                gridTemplateRows: gridTemplateRows ? gridTemplateRows[windowKey] : null,
            }
        })
    }, [windowKey])

    const drawTableBody = () => {
       
        
        if(!currentRows){
            if(loading) return loading
            return <Loading />
        }

        if(currentRows.length === 0){
            if(ifEmpty) return ifEmpty
            return <Empty />
        }

        return currentRows.map((row, key) => {
            return (
            <div className={`table-row   ${onRowClick ? "pointer" : ""} ${rowClass ? rowClass(row) : ''}`} style={style.row} key={key} onClick={(e) => {
                e.stopPropagation()
                if(e.ctrlKey){
                    if(onCtrlClick) onCtrlClick(row)
                    return
                }
                if(onRowClick) onRowClick(row)
            }}>
                {
                    columns.map((column, key) => {
                        return <div key={key} className={`d-flex overflow-hidden ${headers[key]?.justify ? `justify-md-content-${headers[key]?.justify}` : ''}`} style={column.grid && column.grid[windowKey] ? {gridArea: column.grid[windowKey]} : {display: 'none'}}>{column.display(row)}</div>
                    })
                }
            </div>)
        })
    }

    return (
        <div>
            {exportData ? <CSVDownload data={exportData}/> : null}
        <div className="table-grid" ref={tableRef} >
            {
                filters
            }
            <div className="table-wrapper">
            {
                headers ? (
                    <div className="table-header" style={style.header}>
                        {
                            headers.map((header, key) => {
                                return (
                                    <div className={`row gx-1 ${header.classes} ${header.justify ? `justify-md-content-${headers[key]?.justify}` : ''}`} key={key} style={columns[key].grid && columns[key].grid[windowKey] ? {gridArea: columns[key].grid[windowKey]} : {display: 'none'}} >
                                        <div className="col-auto">
                                            <span 
                                                className={ header.sort ? "pointer" : ''} 
                                                onClick={() => {
                                                    if(header.sort){
                                                        if(sort.key === header.key){
                                                            setUserAction(true)
                                                            setSort({
                                                                ...sort,
                                                                direction: sort.direction === 'ASC' ? 'DESC' : 'ASC'
                                                            })
                                                        }else{
                                                            setUserAction(true)
                                                            setSort({
                                                                key: header.key,
                                                                direction: 'ASC'
                                                            })
                                                        }
                                                    }}
                                                }
                                            >{header.title}</span>
                                        </div>
                                        <div className="col-auto">
                                        { sort && sort.key === header.key ? <FontAwesomeIcon icon={['fas', sort.direction === 'ASC' ? 'caret-down' : 'caret-up']} /> : null}
                                            
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
            ) : null
        }
            <div className={"table-body" }>
                
                    {
                        drawTableBody()
                    }
                
            </div>
            {
                headers && headers.filter((h) => h.total).length ? (

                
                <div className="table-total" style={style.header}>
                {
                    headers.map((header, key) => {
                            return (
                                <div key={key} style={columns[key].grid && columns[key].grid[windowKey] ? {gridArea: columns[key].grid[windowKey]} : {display: 'none'}} >
                                    <span className={key === 0 ? 'ps-2' : ''}>
                                        {
                                            key === 0 ? 'Total' : (header.total ? 
                                                ( header.totalCalculation ? header.totalCalculation(header.key) : getTotal(header)) 
                                            : '')
                                        }
                                    </span>
                                </div>
                            )
                        })
                    }
                </div>
            ) : null
            }
            </div>
        </div>
            
            <div className="table-footer py-3">
                <div className="row justify-content-between">
                    <div className="col-auto">
                        {showResult && rows && currentRows ? <span>Showing <b>{drawCurrentVisibleRows()}</b> of total <b>{rows.length}</b> {unit}</span> : null}
                    </div>
                    <div className="col-auto">
                        <div className="pagination">
                            <span  onClick={() => updatePageLimit(pageLimit - 5)}><FontAwesomeIcon icon={['fal','chevron-left']} /></span>
                            <span >{pageLimit} per page</span>
                            <span onClick={() => updatePageLimit(pageLimit + 5)}><FontAwesomeIcon icon={['fal','chevron-right']} /></span>
                        </div>
                    </div>
                    <div className="col-auto">
                                {
                                    paginationSteps > 1 ? drawPagination() : null
                                }
                    </div>
                </div>
            </div>
                
        </div>
    )
}

function Empty(){
    return (
        <div className="d-flex align-items-center justify-content-center">
            <div className="d-flex flex-column align-items-center py-5">
                <FontAwesomeIcon  size="5x" icon={['fad', 'box-open']} className="text-warning mb-4" />
                <h5 className="bold">No results</h5>
                <p>There are no results for your query</p>                
            </div>
        </div>
    )
}

function Loading(){
    return (
        <div className="py-5 d-flex flex-column justify-content-center align-items-center">
            <div className="bg-success-tint mb-3" style={{width: 70, height: 70, borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <span className="fa-stack fa-lg">
                
                    <FontAwesomeIcon fixedWidth size="sm" icon={['fad', 'hourglass']} className="fa-stack-1x text-secondary" />
                    <FontAwesomeIcon fixedWidth spin size="2x" icon={['fad', 'spinner-third']} className="fa-stack-1x text-primary" />
                </span>
            </div>
            <div className="text-center">
                <h6 className="bold text-primary">Getting your content...</h6>
                <p>Please hold on for a few moments!</p>
            </div>
        </div>
    )
}